import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {localizeKey, localizeArt} from './../localize.js';
import axiosInstance from './../axiosInstance.js';
import MyUserContext from './../myUserContext.js';
import Collapsible from './Collapsible';
import MyChatComponent from './MyChatComponent';
import { Link } from 'react-router-dom';
import ShareButton from './ShareButton';
import LinkInput from './LinkInput';
import TextUserPair from './TextUserPair';

// const TextUserPair = ({user}) => {
//   const myUser = useContext(MyUserContext);

//   const [inputText, setInputText] = useState('');

//   // Status of recent recommendation.
//   const [errText, setErrText] = useState(null);

//   const [successText, setSuccessText] = useState(null);
//   const [successResp, setSuccessResp] = useState(null);

//   const handleRecommend = (userId, link) => {
//     axiosInstance
//     .post('/postRecommendation', { toUserId: userId, wikiArticleLink: link })
//     .then((response) => {
//       setSuccessText(localizeKey('recommendationPostSuccess', language));
//       setErrText(null);
//       setSuccessResp(response.data);
//     })
//     .catch((error) => {
//       setErrText(error.message);
//       setSuccessText(null);
//       setSuccessResp(null);
//     });
//   }

//   const language = myUser ? myUser.myUserSettings.lang : 'ru';

//   return (
//     <div class="container mx-auto">
//       {errText && <p style={{ color: 'red' }}> {errText} </p>}
//       {successText &&
//         <>
//           <div class="text-center text-green-900"> {successText} </div>
//           <div class="flex justify-center">
//             <ShareButton
//               url={
//                 "https://wowcontent.lol/recommendations/" + successResp.recommendation.id
//               }
//               title={
//                 localizeArt(successResp.art).name
//               }
//               text={
//                 localizeKey('shareRecomText', language) + ': ' + localizeArt(successResp.art).name
//               }
//               overrideButtonText={
//                 localizeKey('shareRecomNotMiss', language)
//               }
//             />
//           </div>
//         </>
//       }

//       <div className="container mx-auto flex min-w-0 w-full lg:w-1/2 gap-x-4">
//         <Link to={`/users/${user.id}/likes`}>
//           <img className="my-3 h-12 w-12 flex-none rounded-full bg-gray-50" src="https://pbs.twimg.com/media/D_TAy_fXoAEq3iw.png" alt="" />
//         </Link>
//         <div className="min-w-0 w-full flex-auto">
//           <Link to={`/users/${user.id}/likes`}>
//             <p className="text-sm mt-3 font-semibold leading-6 text-gray-900 hover:underline min-w-min">{user.userName + (myUser && myUser.myUserInfo.myUserId === user.id ? " (me)" : "")}</p>
//           </Link>
//           {
//             myUser && myUser.myUserInfo.myUserId !== user.id &&
//             <>              
//               <LinkInput setOutsideTextInput={setInputText} />
//               <button type="submit" onClick={()=>{handleRecommend(user.id, inputText);}} class="mb-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">{localizeKey('recommend', language)}</button>
//             </>
//           }
//         </div>
//       </div>
//     </div>
//   )
// };

const Users = () => {
  const myUser = useContext(MyUserContext);

  // Displayed users.
  const [users, setUsers] = useState(null);

  const updateUsers = () => {
    axiosInstance
    .get(`/getUsers`)
    .then((response) => {
      response.data.shift(); // get rid of own record
      setUsers(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  const [unfinishedRecomsCount, setUnfinishedRecomsCount] = useState(null);
  const fetchUnfinishedRecomsCount = () => {
    axiosInstance
    .get('/recommendations/unfinished/count')
    .then((response) => {
      setUnfinishedRecomsCount(response.data.unfinished);
    })
    .catch((error) => {
      console.error(error.message);
    })
  }

  if (users === null) {
    updateUsers();
    fetchUnfinishedRecomsCount();
    return (
      <div></div>
    )
  }

  const recommendationsLink = `/recommendations/toMe`;

  const language = myUser ? myUser.myUserSettings.lang : 'ru';

  return (
    <div class="container mx-auto flex justify-center items-center flex-col w-full lg:w-2/3">
      <>
        <br/>
        <div class="flex justify-center">
          <div>
            <Link class="text-blue-500 hover:text-blue-800" to={recommendationsLink}>
              {localizeKey('personalRecommendations', language)}
              {(unfinishedRecomsCount && unfinishedRecomsCount > 0) ? " (" + unfinishedRecomsCount + ")" : ""}
            </Link>
          </div>
          <br/>
          <br/>
        </div>
      </>

      <ul role="list" className="container mx-auto divide-y divide-gray-100 px-8">
        {users.map((user) => (
          <TextUserPair user={user} hideRecomInput={true} />          
        ))}
      </ul>
    </div>
  );
};

export default Users;