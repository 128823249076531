import React, { useState } from "react";

const SelectableTags = ({ tags, onSelectionChange, language }) => {
  const [selectedTags, setSelectedTags] = useState([]);

  const toggleTagSelection = (selectedTag) => {
    let updatedSelection;
    if (selectedTags.find((t) => t.tag.name === selectedTag.tag.name)) {
      // Unselecting the tag
      updatedSelection = selectedTags.filter((t) => t.tag.name !== selectedTag.tag.name);
    } else {
      // Selecting the tag
      updatedSelection = [...selectedTags, selectedTag];
    }
    setSelectedTags(updatedSelection);
    onSelectionChange(updatedSelection); // Call the callback with the updated selection
  };

  // It should be handled in backend.
  const isManualTag = (name) => {
    if (name === "like" || name === "todo" || name === "finished") {
      return true;
    }
    return false;
  }

  const tagName = (name) => {
    const mapping = {
      'en': {
        'like': 'favorite',
        'todo': 'todo',
        'finished': 'finished'
      },
      'ru': {
        'like': 'любимое',
        'todo': 'планы',
        'finished': 'ознакомлен(а)',

        'book': 'книга',
        'movie': 'фильм',
        'game': 'игра'
      }
    }
    const val = mapping[language][name];
    if (val === undefined) {
      return name;
    } else {
      return val;
    }
  }

  return (
    <div className="flex overflow-x-auto space-x-2 p-2">
      {tags.map((tagObject, index) => {
        const { name, creatorUser } = tagObject.tag;
        const { count } = tagObject;

        const tagStyleUnselected = creatorUser ? "bg-gray-200 text-gray-700" : ( isManualTag(name) ? "bg-red-200/50 text-gray-700" : "bg-green-200/50 text-gray-700");

        return (
          <div
            key={index}
            className={`cursor-pointer px-4 py-2 rounded whitespace-nowrap ${
              selectedTags.find((t) => t.tag.name === name)
                ? "bg-blue-600 text-white"
                : tagStyleUnselected
            }`}
            onClick={() => toggleTagSelection(tagObject)}
          >
            {"#"+tagName(name)} <span className="text-sm text-gray-500">({count})</span>
          </div>
        );
      })}
    </div>
  );
};

export default SelectableTags;