import { useState, createContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
// import App from './App'; // deprecated
import './App.css';

import ArtEvent from './components/ArtEvent';
import LikeEvents from './components/LikeEvents';
import TodoEvents from './components/TodoEvents';
import RecommendationEvents from './components/RecommendationEvents';
import UserPage from './components/UserPage';
import Users from './components/Users';
import Settings from './components/Settings';
import Notifications from './components/Notifications';
import FinishedEvents from './components/FinishedEvents';
import PersonalArtEvents from './components/PersonalArtEvents';
import AddEvent from './components/AddEvent';
import ArtPage from './components/ArtPage';

import Card from './components/Card';

import NewUser from './components/NewUser';
import axiosInstance from './axiosInstance.js';
import MyUserContext from './myUserContext.js';
import * as oauth from './oauth.js';
import {localizeKey, localizeArt} from './localize.js';

import { Helmet } from 'react-helmet';

function MainPage() {
  const [isRussianSpeaking, setIsRussianSpeaking] = useState(true); // TODO

  const [loginState, setLoginState] = useState('unknown'); // also, loggedIn and loggedOut
  const [oauthLoginURL, setOauthLoginURL] = useState(null);

  const [myUserInfo, setMyUserInfo] = useState(null);
  const [myUserSettings, setMyUserSettings] = useState(null);

  const [jwtObtained, setJWTObtained] = useState(false); // TODO: better use one more loginState (oauthRedirecting?)

  const [unreadCount, setUnreadCount] = useState(null);

  const [selectedTab, setSelectedTab] = useState(0); // profile page is 0, increments to the right
  const [repeatedEnter, setRepeatedEnter] = useState(false);

  const updateMyUserInfo = () => {
    axiosInstance
    .get(`/checkLoggedIn`)
    .then((response) => {     
      setMyUserInfo(response.data);
      setLoginState('loggedIn');
    })
    .catch(error => {
      if (error.response.status === 401) {
        setLoginState('loggedOut');
        setJWTObtained(false);
      } else {
        console.error('Error checking login status:', error);  
      }
    });
  }

  const fetchMyUserSettings = () => {
    axiosInstance
    .get(`/user/settings`)
    .then((response) => {
      if (response.status === 200) {
        setMyUserSettings(response.data);
      }
    })
    .catch(error => {
      console.error('Error checking login status:', error);
    });
  }
  const updateMyUserSettings = (lang, username, avatarUrl) => {
    setMyUserSettings({
      'lang': lang,
      'userName': username,
      'avatarUrl': avatarUrl
    })
  }

  const oauthLoginURLReceivedCallback = (url) => {
    setOauthLoginURL(url);
  }
  const handleLoginWithGoogle = () => {
    window.location.href = oauthLoginURL;
  };

  const fetchUnreadCount = () => {
    axiosInstance
    .get('/notifications/unreadCount')
    .then((response) => {
      setUnreadCount(response.data.unreadCount);
    })
    .catch(error => {
      console.error('Failed to fetch unread notification count:', error);
    })
  };

  // const navigate = useNavigate();
  const onGoogleLoginSuccess = (response) => {
    // console.log(response);
    const { credential } = response;
    window.location.href = "https://wowcontent.lol/googleOauthCallback?code=" + credential;
    // navigate("/googleOauthCallback?code="+credential);
  }

  // TODO: refactor, this block is ugly.
  let isOauthRedirect = false;
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  if (code !== null) {
    isOauthRedirect = true;
  }

  if (loginState === 'unknown' && !isOauthRedirect) {
    updateMyUserInfo();
    return <div></div>
  }

  if (loginState === 'loggedOut' || isOauthRedirect) {
    const language = 'ru';

    if (oauthLoginURL === null && !isOauthRedirect) {
      oauth.fetchOauthLoginLink(oauthLoginURLReceivedCallback);
      return (
        <div></div>
      )
    }

    return (
      <div class="container mx-auto flex justify-center">        
        <Router>
          {/*
          <ul class="container mx-auto inline-block text-center lg:flex lg:justify-between w-full lg:w-3/4 xl:flex xl:justify-between xl:w-2/3 2xl:flex 2xl:justify-between 2xl:w-1/2">
            <li class="mr-6">
              <Link class="text-blue-500 hover:text-blue-800" to="/">{localizeKey('home', language)}</Link>
            </li>
            <li class="mr-6">
              <Link class="text-blue-500 hover:text-blue-800" to="/recommendations/toMe">{localizeKey('recommendedToMe', language)}</Link>
            </li>
            <li class="mr-6">
              <Link class="text-blue-500 hover:text-blue-800" to="/recommendations/fromMe">{localizeKey('recommendedByMe', language)}</Link>
            </li>
            <li class="mr-6">
              <Link class="text-blue-500 hover:text-blue-800" to="/todos">{localizeKey('myTodos', language)}</Link>
            </li>
            <li class="mr-6">
              <Link class="text-blue-500 hover:text-blue-800" to="/users">{localizeKey('users', language)}</Link>
            </li>
          </ul>
          */}

          <Routes>
            <Route exact path="/" element={
              jwtObtained ? <div>Loading...</div> :
                       
                          <div className="flex justify-center items-center h-screen">
                            {
                              isRussianSpeaking ?
                                <div className="text-center p-6 max-w-lg bg-white shadow-lg rounded-lg">
                                  <h1 className="text-4xl font-bold mb-4 text-gray-900">Организуйте свой контент</h1>
                                  <p className="text-xl font-medium mb-2 text-gray-700">
                                    Книги, фильмы и игры в одном приложении
                                  </p>
                                  <p className="text-lg leading-relaxed text-gray-600 mb-4">
                                    Ведите удобные списки и делайте персональные рекомендации друзьям
                                  </p>
                                  <div onClick={handleLoginWithGoogle} class="flex justify-center items-center hover:cursor-pointer border border-black rounded-full text-lg">
                                    <img src="google-login-image_no_text.png" alt="Войти через Google" />
                                    Войти через Google
                                  </div>
                                </div> :

                                <div className="text-center p-6 max-w-lg bg-white shadow-lg rounded-lg">
                                  <h1 className="text-4xl font-bold mb-4 text-gray-900">Organize Your Content</h1>
                                  <p className="text-xl font-medium mb-2 text-gray-700">
                                    Books, Movies, and Videogames
                                  </p>
                                  <p className="text-lg leading-relaxed text-gray-600 mb-4">
                                    Keep track of your favorites, plans, what you've simply checked out, of personal recommendations to friends.
                                  </p>
                                  <div class="flex justify-center">
                                    <img style={{ cursor: 'pointer' }} onClick={handleLoginWithGoogle} src="google-login-image.png" alt="Login with Google" />
                                  </div>
                                </div>
                            }                            
                          </div>
                       
            } />
            <Route exact path="/users/:pathUserId/likes" element={<LikeEvents key="someonesLikes" userId={null} />} />


            <Route exact path="/recommendations/toMe" element={<img style={{ cursor: 'pointer' }} onClick={handleLoginWithGoogle} src="google-login-image.png" alt="Login with Google" />} />
            <Route exact path="/recommendations/fromMe" element={<img style={{ cursor: 'pointer' }} onClick={handleLoginWithGoogle} src="google-login-image.png" alt="Login with Google" />} />} />

            <Route exact path="/todos" element={<img style={{ cursor: 'pointer' }} onClick={handleLoginWithGoogle} src="google-login-image.png" alt="Login with Google" />} />

            <Route exact path="/users" element={<Users />} />

            <Route exact path="/settings" element={<img style={{ cursor: 'pointer' }} onClick={handleLoginWithGoogle} src="google-login-image.png" alt="Login with Google" />} />} />

            <Route exact path="/recommendations/:id" element={<ArtEvent eventType="recommendation"/>} />
            <Route exact path="/likes/:id" element={<ArtEvent eventType="like"/>} />

            <Route exact path="/arts/:id" element={<ArtPage />} />

            <Route exact path="/artEvents/:id" element={<ArtEvent eventType="artEvent"/>} />

            <Route exact path="/googleOauthCallback" element={<oauth.OauthCallbackHandler onJWTObtained={() => {
              setJWTObtained(true);
              updateMyUserInfo();
            }}/>} />

            <Route exact path="/newUser" element={<NewUser />} />
          </Routes>
        </Router>
      </div>
    )
  }

  // loginState == loggedIn

  if (myUserSettings === null && unreadCount === null) {
    fetchMyUserSettings();
    fetchUnreadCount();
    return <div></div>
  }

  if (myUserSettings === null || unreadCount === null) {
    return <div></div>
  }

  const language = myUserSettings.lang;

  // alert("selectedTab=" + selectedTab);
  // alert("repeatedEnter=" + repeatedEnter);

  return (
    <div class="container mx-auto">
      <MyUserContext.Provider value={{myUserInfo: myUserInfo, myUserSettings: myUserSettings}}>
        <Router>
          <Helmet>
              <title>Wow content lol!</title>
              <meta name="description" content="Wow content lol!" />
          </Helmet>

          <ul class="container fixed h-20 pb-5 md:pb-0 md:h-14 bottom-0 md:top-0 border-t md:border-b md:border-t-0 z-50 w-full px-4 md:px-10 border-gray-300 border-opacity-50 left-1/2 transform -translate-x-1/2 bg-white mx-auto inline-block text-center flex justify-between items-center w-5/6 lg:w-3/4 xl:flex xl:justify-between xl:w-2/3 2xl:flex 2xl:justify-between 2xl:w-1/2">
            <li>
              <Link class="" to="/" onClick={()=> { setRepeatedEnter(selectedTab === 0); setSelectedTab(0); }}>
                <img
                  src={myUserSettings.avatarUrl}
                  class="object-cover w-10 h-10 flex-none rounded-full cursor-pointer"
                />
              </Link>
            </li>
            <li>
              <Link class="" to="/users" onClick={()=> { setRepeatedEnter(selectedTab === 1); setSelectedTab(1); }}>
                <div class="container mx-auto flex justify-center items-center">
                  <div class="group">
                    <div class="flex group-hover:hidden">
                      <img class="mt-0.5 max-w-9 max-h-9" src="/users_icon.png" />
                    </div>
                    <div class="flex hidden group-hover:flex">
                      <img class="mt-0.5 max-w-9 max-h-9" src="/users_icon_hover.png" />
                    </div>
                  </div>

                </div>
              </Link>
            </li>
            <li>
              <Link class="" to="/addEvent" onClick={()=> { setRepeatedEnter(selectedTab === 2); setSelectedTab(2); }}>
                <div class="container mx-auto flex justify-center items-center">
                  <div class="group">
                    <div class="flex group-hover:hidden">
                      <img class="mt-0.5 max-w-11 max-h-11" src="/add_icon.png" />
                    </div>
                    <div class="flex hidden group-hover:flex">
                      <img class="mt-0.5 max-w-11 max-h-11" src="/add_icon_hover.png" />
                    </div>
                  </div>

                </div>
              </Link>
            </li>
            <li>
              <Link class="" to="/settings" onClick={()=> { setRepeatedEnter(selectedTab === 3); setSelectedTab(3); }}>
                <div class="container mx-auto flex justify-center items-center">
                  <div class="group">
                    <div class="flex group-hover:hidden">
                      <img class="mt-0.5 max-w-9 max-h-9" src="/settings_icon.png" />
                    </div>
                    <div class="flex hidden group-hover:flex">
                      <img class="mt-0.5 max-w-9 max-h-9" src="/settings_icon_hover.png" />
                    </div>
                  </div>

                </div>
              </Link>
            </li>
            <li>
              <Link class="" to="/notifications" onClick={()=> { setRepeatedEnter(selectedTab === 4); setSelectedTab(4); }}>
                <div class="container mx-auto flex justify-center items-center">
                  <div class="group">
                    <div class="flex group-hover:hidden">
                      <img class="mt-0.5 max-w-9 max-h-9" src="/notifications_icon.png" />
                      <span class="text-blue-500">
                        {unreadCount ? " (" + unreadCount + ")" : ""}
                      </span>
                    </div>
                    <div class="flex hidden group-hover:flex">
                      <img class="mt-0.5 max-w-9 max-h-9" src="/notifications_icon_hover.png" />
                      <span class="text-blue-800">
                        {unreadCount ? " (" + unreadCount + ")" : ""}
                      </span>
                    </div>
                  </div>

                </div>
              </Link>
            </li>
          </ul>

          <div className="pb-20 md:pt-14 overflow-y-auto w-full">
            <Routes class="overflow-y-auto">
              <Route exact path="/" element={<PersonalArtEvents eventType="like" key="homeLikes" userId={myUserInfo.myUserId} />} />

              <Route exact path="/addEvent" element={<AddEvent repeatedEnter={repeatedEnter}/>} />
              
              <Route exact path="/users/:pathUserId" element={<UserPage userId={null} />} />
              <Route exact path="/users/:pathUserId/todos" element={<PersonalArtEvents eventType="todo" key="someonesTodo" userId={null} />} />
              <Route exact path="/users/:pathUserId/finished" element={<PersonalArtEvents eventType="finished" key="someonesFinished" userId={null} />} />
              <Route exact path="/users/:pathUserId/likes" element={<PersonalArtEvents eventType="like" key="someonesLikes" userId={null} />} />
              
              <Route exact path="/recommendations/toMe" element={<RecommendationEvents key="recomToMe" params={{fromMe: false}} />} />
              <Route exact path="/recommendations/fromMe" element={<RecommendationEvents key="recomFromMe"  params={{fromMe: true}} />} />

              <Route exact path="/finished" element={<PersonalArtEvents eventType="finished" userId={myUserInfo.myUserId} />} />
              <Route exact path="/todos" element={<PersonalArtEvents eventType="todo" key="todos" userId={myUserInfo.myUserId} />} />

              <Route exact path="/users" element={<Users />} />

              <Route exact path="/settings" element={<Settings onSettingsChange={updateMyUserSettings} />} />

              <Route exact path="/recommendations/:id" element={<ArtEvent eventType="recommendation"/>} />
              <Route exact path="/likes/:id" element={<ArtEvent eventType="like"/>} />
              <Route exact path="/todos/:id" element={<ArtEvent eventType="todo"/>} />
              <Route exact path="/artEvents/:id" element={<ArtEvent eventType="artEvent"/>} />

              <Route exact path="/arts/:id" element={<ArtPage />} />

              <Route exact path="/notifications" element={<Notifications unreadCountCallback={setUnreadCount} />} />

              <Route exact path="/newUser" element={<NewUser />} />
            </Routes>
          </div>
        </Router>
      </MyUserContext.Provider>
    </div>
  );
  // TODO: Put in footer: Icons by <a target="_blank" href="https://icons8.com">Icons8</a>
}

export default MainPage;