import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { useParams } from "react-router-dom";
import {localizeKey, localizeArt} from './../localize.js';
import axiosInstance from './../axiosInstance.js';
import MyUserContext from './../myUserContext.js';
import Collapsible from './Collapsible';
import MyChatComponent from './MyChatComponent';
import CardEventArtChat from './CardEventArtChat';
import LinkInput from './LinkInput';
import TextUserPair from './TextUserPair';
import SelectableTags from './SelectableTags';
import { Link } from 'react-router-dom';

const PersonalArtEvents = ({eventType, userId}) => {
  const myUser = useContext(MyUserContext);
  const [finishedInput, setFinishedInput] = useState("");
  const [finishedEvents, setFinishedEvents] = useState(null);

  const [hasAccess, setHasAccess] = useState(true);

  const { pathUserId } = useParams();
  const effectiveUserId = () => {
    // UserID from props or path param.
    let effectiveUserId = userId;
    if (!effectiveUserId) {
      effectiveUserId = pathUserId;
    }
    return effectiveUserId;
  }

  const initEventsFetchUrl = `/artEvents/${effectiveUserId()}/${eventType}`;
  const [nextUrl, setNextUrl] = useState(initEventsFetchUrl);
  const [loading, setLoading] = useState(false);
  const observer = useRef();

  const [fetching, setFetching] = useState(false);

  const [curUser, setCurUser] = useState(null); // some other user
  const fetchCurUser = () => {
    axiosInstance
    .get(`/users/${effectiveUserId()}`)
    .then((response) => {
      setCurUser(response.data);
    })
    .catch((error) => {
      console.error(error);      
    });
  }

  const [relevantTags, setRelevantTags] = useState(null);
  const fetchRelevantTagNames = (alreadySetTags=[]) => {
    const tagNames = alreadySetTags.map((t) => { return t.tag.name; });
    const tagParams = tagNames.map((n) => { return "alreadySetTags="+n; }).join("&");
    axiosInstance
    .get(`/relevant/tags?userId=${effectiveUserId()}&type=${eventType}&${tagParams}`)
    .then((response) => {
      setRelevantTags(response.data);
    })
    .catch((error) => {
      console.error(error);
    })
  }

  const [unfinishedRecomsCount, setUnfinishedRecomsCount] = useState(null);
  const fetchUnfinishedRecomsCount = () => {
    axiosInstance
    .get('/recommendations/unfinished/count')
    .then((response) => {
      setUnfinishedRecomsCount(response.data.unfinished);
    })
    .catch((error) => {
      console.error(error.message);
    })
  }

  const fetchFinishedEvents = (nextUrl, fromScratch = false) => {
    if (!nextUrl || loading) {
      return;
    }
    setLoading(true);
    axiosInstance
    .get(nextUrl)
    .then((response) => {
      if (finishedEvents === null || fromScratch) {
        setFinishedEvents(response.data.data);
      } else {
        setFinishedEvents(prevObjects => [...prevObjects, ...response.data.data]);  
      }
      setNextUrl(response.data.nextPageUrl);
    })
    .catch((error) => {
      if (error.response) {
        // Server responded with a status other than 200 range
        if (error.response.status === 403) {
          // Handle 403 Forbidden
          setHasAccess(false);
          console.log("Setting has access to false");
        } else {
          console.error('Error: ', error.response.data);
        }
      } else {
        console.error(error);  
      }
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const handleFinishedInputChange = (event) => {
    setFinishedInput(event.target.value);
  };
  const handleFinished = () => {
    axiosInstance
    .post(`/artEvents`, { wikiArticleLink: finishedInput, type: eventType })
    .then((response) => {
      if (finishedEvents === null) {
        setFinishedEvents(response.data);
      } else {
        setFinishedEvents(prevObjects => [response.data, ...prevObjects]);  
      }
      setFinishedInput("");
    })
    .catch((error) => {
      console.error(error);
    });
  };

  const lastObjectRef = useCallback(node => {
    if (loading) return;

    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        fetchFinishedEvents(nextUrl);
      }
    });
    if (node) {
      observer.current.observe(node);
    }
  }, [loading, nextUrl, fetchFinishedEvents]);

  const curUserIsMe = () => {
    return (myUser && effectiveUserId() === myUser.myUserInfo.myUserId);
  }

  if (hasAccess && (finishedEvents === null || (!curUserIsMe() && curUser === null))) {
    if (fetching === false) {
      setFetching(true);
      fetchFinishedEvents(nextUrl);
      fetchUnfinishedRecomsCount();
      if (!curUserIsMe()) {
        fetchCurUser();
      }
      fetchRelevantTagNames();
    }
    return (
      <div></div>
    )
  } else {
    // setFetching(false); // Needed to comment to avoid re-render, wtf
  }

  const language = myUser !== null ? myUser.myUserSettings.lang : 'ru';

  let buttonTextLocalizeKey = "addFinished";
  if (eventType === "like") {
    buttonTextLocalizeKey = "like";
  } else if (eventType === "todo") {
    buttonTextLocalizeKey = "addTodo";
  }

  // console.log("finishedEvents[0].event.chatId: " + finishedEvents[0].event.chatId);

  // Links to other types of events: start
  // TODO: move it to a separate user page component.
  const likesLink = `/users/${effectiveUserId()}/likes`;
  const classLikes = (eventType === "like" ? "underline" : "");

  const todosLink = `/users/${effectiveUserId()}/todos`;
  const classTodos = (eventType === "todo" ? "underline" : "");

  const finishedLink = `/users/${effectiveUserId()}/finished`;
  const classFinished = (eventType === "finished" ? "underline" : "");

  // Links to other types of events: end

  return (
    <div class="container mx-auto px-1 md:px-2 w-full lg:w-1/2">
      <div class="container mx-auto mt-4">
        {
          myUser && effectiveUserId() !== myUser.myUserInfo.myUserId &&
            <TextUserPair user={curUser} userId={effectiveUserId()} hideRecomInput={true}/>
        }        
      </div>
      {
      (hasAccess === false) ?
        <div class="flex justify-center text-center">
          {language === "ru" ? "Приватный аккаунт, подпишитесь на пользователя и дождитесь подтверждения" : "Account is private, subscribe to user and wait for approval"}
        </div> 
        :
          <>
            {
            (finishedEvents && finishedEvents.length == 0) &&
              <div class="mt-5 justify-center text-center">
                {
                language === "ru"
                ?                  
                  (myUser && effectiveUserId() === myUser.myUserInfo.myUserId)
                  ?
                    <>
                      Вы пока не добавили ни одного произведения в свою библиотеку.
                      Перейдите в поиск <img class="inline max-w-6 max-h-6" src="/add_icon.png" /> и добавьте произведения.
                    </>
                  :
                    <>
                      Пользователь пока не добавил ни одного произведения в свою библиотеку.
                    </>
                :
                  (myUser && effectiveUserId() === myUser.myUserInfo.myUserId)
                  ?
                    <>
                      You have not added any arts yet to your library.
                      Go to search <img class="inline max-w-6 max-h-6" src="/add_icon.png" /> and add arts.
                    </>
                  :
                    <>
                      The user has not added any arts yet to their library.
                    </>
                }
              </div>
            }

            {
            <div class="fixed md:static border-t md:border-t-0 border-gray-300 border-dotted bottom-20 left-0 w-full bg-white z-50 justify-center">
              <div class="mt-2 mb-4">
                <SelectableTags language={language} tags={relevantTags !== null ? relevantTags : []} onSelectionChange={(tags) => {
                  const tagParams = tags.map((t) => { return "tags="+t.tag.name+"&"; }).join("");
                  const eventsFetchUrl = `/artEvents/${effectiveUserId()}/${eventType}?${tagParams}`;
                  fetchRelevantTagNames(tags);
                  fetchFinishedEvents(eventsFetchUrl, true);
                }} />
              </div>
            </div>
            }

            <div class="flex-grow overflow-y-auto mb-16">              
              <div>
                <ul className="container mx-auto divide-y divide-gray-100">
                  {finishedEvents.map(
                    (artEvent, index) => {
                      if (index === finishedEvents.length - 1) {              
                        return (
                          <li ref={lastObjectRef}>
                            <CardEventArtChat key={artEvent.event.id} userInfo={{name: artEvent.eventOwnerUserName, avatarUrl: artEvent.eventOwnerUserAvatarUrl}} event={artEvent.event} chatInfo={artEvent.chat} eventUserchatInfo={artEvent.chat} eventType={eventType} art={artEvent.art} language={language} />
                          </li>
                        )
                      } else {
                        return (
                          <li>
                            <CardEventArtChat key={artEvent.event.id} userInfo={{name: artEvent.eventOwnerUserName, avatarUrl: artEvent.eventOwnerUserAvatarUrl}} event={artEvent.event} chatInfo={artEvent.chat} eventType={eventType} art={artEvent.art} language={language} />
                          </li>
                        )
                      }
                    }
                  )}
                </ul>
              </div>
            </div>
          </>
      }
      
    </div>
  );
}

export default PersonalArtEvents;